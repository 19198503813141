'use client';

import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CustomerIOEvents } from '@innerwell/dtos';
import { usePathname, useRouter } from 'next/navigation';

import { BackgroundGraphicBowlOfCircles } from '@/components/BackgroundGraphics';
import DefaultHeader from '@/components/Headers/DefaultHeader';
import { Icon } from '@/components/Icon';
import { CircleBottomMask, Lamp } from '@/components/Images';
import ExplanationBox from '@/components/ListingItems/ExplanationBox';
import { NextLink } from '@/components/NextLink/NextLink';

import { webApiClient } from '@/api-client/apiClient';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { useSession } from '@/contexts/session-context';
import Layout from '@/layouts/Layout';
import { useEffect } from 'react';

export interface IBreakStepLayoutProps {
   heading?: string | React.ReactNode;
   headingDesktopSize?: string;
   text?: string | React.ReactNode;
   image?: React.ReactNode;
   desktopImage?: React.ReactNode;
   btnText?: string;
   btnLink?: string;
   onButtonClick?: (props: React.MouseEvent<HTMLButtonElement>) => void;
}

const RejectedRefundStepLayout: React.FC<IBreakStepLayoutProps> = ({
   heading,
   headingDesktopSize,
   text,
   image = (
      <Lamp
         position="absolute"
         top={0}
         left="50%"
         display="block"
         transform="translateY(-100%) translateX(-50%)"
         zIndex={3}
      />
   ),
   desktopImage = <BackgroundGraphicBowlOfCircles />,
}) => {
   const { push } = useRouter();
   const pathname = usePathname();

   const { data: sessionData } = useSession();
   const { isHomeUnlocked } = usePatientProgram();

   useEffect(() => {
      if (sessionData) {
         webApiClient.customerio.track({
            body: {
               id: sessionData['cognito:username'],
               data: { email: sessionData['email'] },
               name: CustomerIOEvents.OnboardingRejected,
            },
         });
      }
   }, [sessionData]);

   if (!heading) {
      // eslint-disable-next-line no-param-reassign
      heading = `Thanks for your interest in Innerwell, ${sessionData?.given_name}`;
   }

   if (!text) {
      // eslint-disable-next-line no-param-reassign
      text = (
         <>
            After careful clinical review of your Digital Intake, we regret to
            inform you that treatment at Innerwell may not be the best fit for
            you. To see a list of mental health clinics that may be better
            suited to help you, visit{' '}
            <NextLink target="_blank" href="https://www.findhelp.org">
               findhelp.org
            </NextLink>
            . <br />
            <br />
            You may also call SAMHSA’s National Helpline at 1-800-662-HELP
            (4357) at any time. If you are experiencing a mental health crisis
            or emergency, please call or text 988 to reach the Suicide & Crisis
            Lifeline, or dial 911.
         </>
      );
   }

   useEffect(() => {
      if (pathname.includes('medical-intake') && isHomeUnlocked === false) {
         push('/plans/choose-a-plan');
      }
   }, [isHomeUnlocked, push, pathname]);

   return (
      <Layout>
         {desktopImage ? (
            <Box
               display={{ base: 'none', md: 'block' }}
               position="fixed"
               right="0"
               bottom="0"
               maxW="50%"
            >
               {desktopImage}
            </Box>
         ) : null}
         <DefaultHeader zIndex={3} pos="relative" />
         <Box
            zIndex={1}
            display="flex"
            flexDirection="column"
            mt={{ base: '222px', md: 0 }}
            h={{ md: 'calc(var(--app-height) - 74px)' }}
         >
            <Box
               h="560px"
               bg={{ base: 'neutral.200', md: 'transparent' }}
               pos="absolute"
               top={0}
               left={0}
               w="full"
            />
            <Box
               display={{ md: 'none' }}
               pos="absolute"
               left="0"
               w="100%"
               top="558px"
               zIndex={4}
            >
               <CircleBottomMask />
            </Box>

            <Box
               w="100%"
               mt={{ md: 'auto' }}
               mb={{ md: 'auto' }}
               pos="relative"
               zIndex={5}
            >
               <Box display={{ md: 'none' }}>{image}</Box>

               <Flex
                  flexDir="column"
                  px={6}
                  pt={30}
                  pb={6}
                  pos="relative"
                  zIndex={2}
                  maxW={{ md: '540px' }}
               >
                  <Heading
                     as="h1"
                     fontSize={{
                        base: '3xl',
                        md: headingDesktopSize || '4xl',
                     }}
                  >
                     {heading}
                  </Heading>
                  {text ? <Text mt={5}>{text}</Text> : null}
               </Flex>

               <Box w="full" p={6} bottom={2} zIndex={6}>
                  <ExplanationBox
                     icon={<Icon name="refund" w={8} h={8} />}
                     text={
                        <Text>
                           If you are entitled for a refund, please allow 5-7
                           business days for it to process.
                        </Text>
                     }
                  />
                  <Box mb={5} />
                  <ExplanationBox
                     icon={<Icon name="user-delete" w={8} h={8} />}
                     text={
                        <Text>
                           All data collected during the screening and
                           onboarding process will be deleted in 30 days.
                        </Text>
                     }
                  />
               </Box>
            </Box>
         </Box>
      </Layout>
   );
};

export default RejectedRefundStepLayout;
